import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { serialize } from 'object-to-formdata';
import { useHistory, useLocation } from 'react-router-dom';
import * as Yup from 'yup';

import { ImageLogo, ImageCamera } from '~/assets/icons';
import Button from '~/components/Button';
import Input from '~/components/Input';
import { useAuth } from '~/hooks/Auth';
import { useToast } from '~/hooks/Toast';
import {
  PageCustomizeFormData,
  PageCustomizeRequest,
  RegisterProfessionalRequestForm,
  SubmitHandler,
} from '~/models/Common';
import { Logo } from '~/models/Common';
import ProfessorService from '~/services/ProfessorService';
import UserSevice from '~/services/UserService';
import { convertPublicName } from '~/utils/convertPublicName';
import errorHandlerToToast from '~/utils/errorHandler';
import Price from '~/utils/Price';

import { Container, ButtonGroup, Content, Label } from './styles';

import PreviewLink from './PreviewLink';

interface PassPropsSignUp {
  data?: RegisterProfessionalRequestForm;
  professionId: number;
  plan: {
    planId: number;
    planAmount: number;
    planName: string;
    numberVideos: number;
  };
}

interface NickNameFromForm {
  nick_name: string;
}

const Signin: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const { signInFromSignUp } = useAuth();
  const { push } = useHistory();
  const { state } = useLocation<PassPropsSignUp | undefined>();
  const [publicName, setPublicName] = useState('');
  const [uploadImage, setUploadImage] = useState<Logo>();

  useEffect(() => {
    if (!state?.data) {
      push('/signIn');
    }
  }, [push, state?.data]);

  const handlePreviewChange = useCallback(
    ({ target: { value = '' } }: ChangeEvent<HTMLInputElement>) => {
      const newPublicName = convertPublicName(value);
      setPublicName(newPublicName);
    },
    [],
  );

  const handleSetImage = ({
    target: { files },
  }: ChangeEvent<HTMLInputElement>): void => {
    if (files) {
      const firstFile = files[0];
      setUploadImage({
        file: firstFile,
        name: URL.createObjectURL(firstFile),
      });
    }
  };

  const handleSubmit: SubmitHandler<NickNameFromForm> = useCallback(
    async ({ nick_name }) => {
      const preData: PageCustomizeFormData = {
        public_name: publicName,
        nick_name,
      };

      try {
        if (!state?.data) throw new Error('Campos faltando na tela anterior');
        const dataForm = state.data;

        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          nick_name: Yup.string()
            .validPublicName()
            .max(32, ({ max }) => `É permitido no máximo ${max} caracter(es).`)
            .min(
              3,
              ({ min }) => `É necessário o mínimo de ${min} caracter(es).`,
            )
            .required('O nome público é obrigatório.'),
        });

        await schema.validate(preData, {
          abortEarly: false,
        });

        const dataJoin = serialize({
          ...dataForm,
          professionId: state.professionId,
          ...preData,
        });
        // comentar para desligar a api
        const {
          data: { message, ...dataFromSignUp },
        } = await UserSevice.signup(dataJoin);

        console.log(dataFromSignUp);

        if (dataFromSignUp.user.role === 'professional') {
          await UserSevice.update({
            userId: dataFromSignUp.user.professional.id,
            data: { amount_receivable: Price.BASE_PRICE } as any,
            type: 'professional',
            token: dataFromSignUp.data.token,
          });
        }

        addToast({
          title: message, // colocar aspas para desligar api
          type: 'success',
        });

        if (uploadImage) {
          const dataUpdate: PageCustomizeRequest = {
            public_name: publicName,
            logo: uploadImage.file,
          };
          const dataUpdateForm = serialize<PageCustomizeRequest>(dataUpdate);

          const { data: responseUpload } = await ProfessorService.updateLogo({
            data: dataUpdateForm,
            token: dataFromSignUp.data.token,
          });

          addToast({
            title: responseUpload.message, // colocar aspas para desligar api
            type: 'success',
          });

          dataFromSignUp.user.avatar = responseUpload.avatar;
        }

        console.log(dataFromSignUp);

        signInFromSignUp(dataFromSignUp);
        push(
          `/${dataFromSignUp.user.name.replace(
            ' ',
            '_',
          )}/signup-payments?professional=true`,
        );
        push(
          `/${dataForm.name.replace(
            ' ',
            '_',
          )}/signup-payments?professional=true`,
          {
            plan: state.plan,
          },
        );
      } catch (error: any) {
        console.log(error);
        const responseErr = errorHandlerToToast({ error, formRef });
        if (responseErr) addToast(responseErr);
      }
    },
    [addToast, publicName, push, signInFromSignUp, state?.data, uploadImage],
  );

  return (
    <Container>
      <img src={ImageLogo} alt="" />
      <h1>Personalize sua página</h1>
      <h6>Defina os detalhes da sua página pública.</h6>
      <Form onSubmit={handleSubmit} ref={formRef} encType="multipart/form-data">
        <Content>
          <label htmlFor="image">
            {uploadImage ? (
              <img
                src={uploadImage.name}
                alt="Prévia de imagem"
                style={{ width: 'auto', height: '100%' }}
              />
            ) : (
              <>
                <img src={ImageCamera} alt="Camera" />
                <span>Logo ou foto</span>
              </>
            )}
            <input
              name="logo"
              type="file"
              accept="image/jpeg,image/png"
              id="image"
              onChange={handleSetImage}
            />
          </label>
          <div>
            <div>
              <Label htmlFor="nick_name">Nome público:</Label>
              <Input
                id="nick_name"
                name="nick_name"
                maxLength={32}
                onChange={handlePreviewChange}
                placeholder="Digite nome seu nome profissional"
              />
            </div>
            <PreviewLink {...{ publicName }} />
          </div>
        </Content>
        <ButtonGroup>
          <Button type="submit" isOutline={false}>
            Continuar
          </Button>
        </ButtonGroup>
      </Form>
    </Container>
  );
};

export default Signin;
